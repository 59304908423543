// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const icon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_212_3556)">
      <path
        d="M2 8.47388C2.05322 8.26493 2.07983 8.05597 2.13305 7.84702C2.63866 6.14925 4.18207 5 5.96499 5C9.66387 5 13.3627 5 17.0616 5C19.0574 5 20.7339 6.43657 20.9734 8.39552C21 8.55224 21 8.70896 21 8.89179C21 10.9813 21 13.0448 21 15.1343C21 17.041 19.5896 18.6604 17.6737 18.9739C17.4608 19 17.2745 19.0261 17.0616 19.0261C13.3361 19.0261 9.63725 19.0261 5.91176 19.0261C3.96919 19 2.29272 17.5373 2.02661 15.6306C2.02661 15.6045 2 15.5522 2 15.5261C2 13.1754 2 10.8246 2 8.47388ZM11.5 17.4328C13.3361 17.4328 15.1723 17.4328 17.0084 17.4328C18.3922 17.4328 19.4034 16.4142 19.43 15.0821C19.43 13.0187 19.43 10.9813 19.43 8.91791C19.43 7.58582 18.3922 6.56716 17.035 6.56716C13.3361 6.56716 9.66387 6.56716 5.9916 6.56716C4.63445 6.56716 3.59664 7.58582 3.59664 8.91791C3.59664 10.9813 3.59664 13.0187 3.59664 15.0821C3.59664 15.1866 3.59664 15.3172 3.62325 15.4478C3.83613 16.6754 4.79412 17.4328 6.04482 17.4328C7.85434 17.4328 9.66387 17.4328 11.5 17.4328Z"
        fill="currentColor"
      />
      <path
        d="M8.33324 8.21265C8.33324 8.291 8.33324 8.36936 8.33324 8.4216C8.33324 9.46638 8.33324 10.5373 8.33324 11.582C8.33324 12.0783 8.51951 12.4962 8.86545 12.8619C9.63716 13.5932 10.3823 14.3507 11.1274 15.082C11.1806 15.1343 11.2338 15.1604 11.287 15.2126C10.3556 15.944 8.62595 16.2052 7.18898 15.4216C5.67217 14.6119 4.84724 12.8358 5.21979 11.1903C5.67217 9.30966 7.26881 8.34324 8.33324 8.21265Z"
        fill="currentColor"
      />
      <path
        d="M9.90332 8.18652C11.1806 8.47384 12.112 9.15294 12.6708 10.2761C13.3361 11.582 13.203 12.8619 12.4313 14.1417C12.3781 14.0895 12.3249 14.0373 12.2717 13.985C11.5798 13.3059 10.9145 12.6268 10.2226 11.9738C10.0098 11.7649 9.90332 11.5298 9.90332 11.2425C9.90332 10.3022 9.90332 9.3619 9.90332 8.39548C9.90332 8.34324 9.90332 8.26488 9.90332 8.18652Z"
        fill="currentColor"
      />
      <path
        d="M16.2631 14.3247C16.5292 14.3247 16.7687 14.3247 17.0348 14.3247C17.4872 14.3247 17.8331 14.6643 17.8331 15.0822C17.8331 15.5001 17.5138 15.8658 17.0614 15.8919C16.5292 15.918 15.9704 15.918 15.4382 15.8919C14.9858 15.8919 14.6399 15.5262 14.6665 15.1083C14.6665 14.6643 15.0124 14.3508 15.4914 14.3508C15.7309 14.3247 15.997 14.3247 16.2631 14.3247Z"
        fill="currentColor"
      />
      <path
        d="M16.2631 9.67538C15.997 9.67538 15.7575 9.67538 15.4914 9.67538C15.039 9.67538 14.6931 9.36194 14.6665 8.91791C14.6399 8.5 14.9858 8.13433 15.4382 8.10821C15.997 8.08209 16.5292 8.08209 17.088 8.10821C17.5404 8.10821 17.8598 8.5 17.8598 8.91791C17.8331 9.33582 17.4872 9.67538 17.0614 9.67538C16.7687 9.67538 16.5026 9.67538 16.2631 9.67538Z"
        fill="currentColor"
      />
      <path
        d="M16.2364 12.7574C15.9436 12.7574 15.6509 12.7835 15.3582 12.7574C14.9857 12.7312 14.6929 12.4439 14.6397 12.0521C14.6131 11.7126 14.8526 11.3469 15.1985 11.2424C15.2518 11.2163 15.305 11.2163 15.3582 11.2163C15.9436 11.2163 16.5025 11.2163 17.0879 11.2163C17.4072 11.2163 17.6999 11.4514 17.7798 11.7648C17.8596 12.0782 17.7798 12.4178 17.5137 12.6006C17.354 12.7051 17.1677 12.7574 16.9814 12.7835C16.742 12.8096 16.5025 12.7835 16.2364 12.7574Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_212_3556">
        <rect
          width="19"
          height="14"
          fill="currentColor"
          transform="translate(2 5)"
        />
      </clipPath>
    </defs>
  </svg>
);

const DashboardResource: ResourceItem = {
  name: resourceName.dashboard,
  identifier: resourceName.dashboard,
  list: `${resourceName.dashboard}`,
  options: { label: 'Dashboard', icon },
};

export default DashboardResource;
